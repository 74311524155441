import { useState, useEffect, Fragment } from 'react';
import { Helmet } from 'react-helmet-async';
import Moment from 'moment';
import { Line } from 'react-chartjs-2';


import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
} from 'chart.js';


ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend
);


console.log(window.location.host);
var API_BASE = "";
if (window.location.host.includes("localhost")) {
	API_BASE = "http://localhost:3001"
}
else {
	API_BASE = "https://adminmatchpricecouk.netlify.app/.netlify/functions/api"
}



function App() {
	const [schedules, setSchedules] = useState([]);
	const [fixture, setFixture] = useState([]);
	const [club, setClub] = useState([]);

	useEffect(() => {

		GetSchedules();
		GetFixture();
		GetClub();

	}, [])

	var section = "home";
	var club_name = "";
	var raw_path = window.location.pathname;
	var search_path = "";
	if (raw_path.includes("/fixture/")) {
		search_path = raw_path.replace("/fixture/", "scraper_")
		section = "fixture";
	}
	if (raw_path.includes("/club/")) {
		search_path = raw_path.replace("/club/", "")
		section = "club";
		club_name = search_path.replace("_", " ");
	}


	const GetSchedules = () => {
		fetch(API_BASE + `/matchpriceschedule?group=${encodeURIComponent("LFT")}`)
			.then(res => res.json())
			.then(data => setSchedules(data))
			.catch(err => console.error("Error:", err));
	}

	const GetFixture = () => {
		fetch(API_BASE + `/matchpricefixture?fixture=${encodeURIComponent(search_path)}`)
			.then(res => res.json())
			.then(data => setFixture(data))
			.catch(err => console.error("Error:", err));
	}

	const GetClub = () => {
		fetch(API_BASE + `/matchpriceclub?group=LFT&club=${encodeURIComponent(search_path)}`)
			.then(res => res.json())
			.then(data => setClub(data))
			.catch(err => console.error("Error:", err));
	}

	if (section === "home") {
		return (
			<Fragment>
				<Helmet>

				</Helmet>
				<div className="App container">
					<h4>Home</h4>
					<div className="schedules">
						{schedules.map(schedule => (
							<div className="schedule" key={schedule._id}>
								<div className="collection">
									<a href={schedule.collection.replace("scraper_", "/fixture/")}>{schedule.collection.replace("scraper_", "")}</a></div>
								<div className="group"></div>
							</div>
						))}
					</div>
				</div>
			</Fragment>

		);
	}
	else if (section === "club") {
		return (
			<Fragment>
				<Helmet>
					<title>Tickets for {search_path.replace("_", " ").toUpperCase()} matches with marketplace history</title>
					<meta name="description" content={"Ticket prices forr " + club_name.toUpperCase() + " matches with our comprehensive ticket pricing history. Monitor the market!"} />
				</Helmet>
				<div className="App container">
					<h4>{club_name}</h4>
					<div className="schedules">
						<table className="styled-table">
							<thead>
								<tr>
									<th>Match Date</th>
									<th>Fixture</th>
									<th>Last Update</th>
									<th>Tickets Available</th>
									<th>Lowest Price</th>
									<th>Price Points</th>
									<th>View</th>
								</tr>
							</thead>
							<tbody>
								{club.map(schedule => {
									Moment.locale('en');
									var formatted_date = schedule.collection.replace("scraper_", "").split('_').slice(0, 3).join('_');
									formatted_date = formatted_date.replaceAll("_", "-");
									var relative_time = Moment(schedule.last_run, 'YYYYMMDD').fromNow();

									formatted_date = Moment(formatted_date).format('MMMM Do YYYY');

									var teams = schedule.collection.replace("scraper_", "").split('_').slice(5).join('_');
									teams = teams.replaceAll("_", " ");
									teams = teams.toLowerCase().split(' ').map(function (s) {
										return s.charAt(0).toUpperCase() + s.substring(1);
									}).join(' ').replace(/ and /ig, ' & ').replace(/Tv/ig, "TV");

									var last_tickets_available = "-";
									if (schedule.last_tickets_available) {
										last_tickets_available = schedule.last_tickets_available.replace("TICKETS AVAILABLE", "");
									}
									var last_price_from = "-";
									if (schedule.last_price_from) {
										last_price_from = schedule.last_price_from.replace("FROM £ ", "");
									}
									var last_buckets = "-";
									if (schedule.last_buckets) {
										last_buckets = schedule.last_buckets;
									}



									return (
										<tr>
											<td>{formatted_date}</td>
											<td>{teams}</td>
											<td>{relative_time}</td>
											<td>{last_tickets_available}</td>
											<td>£{last_price_from}</td>
											<td>{last_buckets}</td>
											<td>
												<div className="schedule" key={schedule._id}>
													<div className="collection">
														<a href={schedule.collection.replace("scraper_", "/fixture/")}>Fixture History</a></div>
													<div className="group"></div>
												</div>
											</td>
										</tr>
									)
								})}
							</tbody>
						</table>
					</div>
				</div>
			</Fragment>

		);
	}
	else if (section === "fixture") {
		let chart_labels = fixture.map((el, index) => {
			return el.date.substring(0, el.date.indexOf("T"));
		});
		let chart_tickets_available = fixture.map((el, index) => {
			return el.tickets_available.replace("TICKETS AVAILABLE", "");
		});

		let chart_price_from = fixture.map((el, index) => {
			return el.price_from.replace("FROM £ ", "");
		});

		let chart_buckets = fixture.map((el, index) => {
			return el.buckets;
		});

		const chart_options = {
			responsive: true,
			plugins: {
				legend: {
					position: 'top',
				},
				title: {
					display: true,
				},
			},
			scales: {
				x: {
					reverse: true,
				},
				y: {
					beginAtZero: true,
				}
			}
		};

		console.log(chart_labels);
		console.log(chart_price_from);
		const chart_tickets_available_data = {
			labels: chart_labels,
			datasets: [
				{
					label: 'Tickets Available',
					data: chart_tickets_available,
					borderColor: 'rgb(255, 196, 17)',
					backgroundColor: 'rgba(255, 196, 17, 0.5)',

				}
			]
		};
		const chart_price_from_data = {
			labels: chart_labels,
			datasets: [
				{
					label: 'Lowest Price',
					data: chart_price_from,
					borderColor: 'rgb(255, 99, 132)',
					backgroundColor: 'rgba(255, 99, 132, 0.5)',

				}
			]
		};
		const chart_buckets_data = {
			labels: chart_labels,
			datasets: [
				{
					label: 'Price Points',
					data: chart_buckets,
					borderColor: 'rgb(125, 35, 255)',
					backgroundColor: 'rgba(178, 128, 251, 0.5)',

				}
			]
		};

		const chart_consolidated_data = {
			labels: chart_labels,
			datasets: [
				{
					label: 'Tickets Available',
					data: chart_tickets_available,
					borderColor: 'rgb(255, 196, 17)',
					backgroundColor: 'rgba(255, 196, 17, 0.5)',

				},
				{
					label: 'Lowest Price',
					data: chart_price_from,
					borderColor: 'rgb(255, 99, 132)',
					backgroundColor: 'rgba(255, 99, 132, 0.5)',

				},
				{
					label: 'Price Points',
					data: chart_buckets,
					borderColor: 'rgb(125, 35, 255)',
					backgroundColor: 'rgba(178, 128, 251, 0.5)',

				}
			]
		};


		var teams = raw_path.replace("/fixture/", "").split('_').slice(5).join('_');
		teams = teams.replaceAll("_", " ");
		teams = teams.toLowerCase().split(' ').map(function (s) {
			return s.charAt(0).toUpperCase() + s.substring(1);
		}).join(' ').replace(/ and /ig, ' & ').replace(/Tv/ig, "TV");

		Moment.locale('en');
		var formatted_date = raw_path.replace("/fixture/", "").split('_').slice(0, 3).join('_');
		formatted_date = formatted_date.replaceAll("_", "-");
		var relative_time = Moment(formatted_date, 'YYYYMMDD').fromNow();

		formatted_date = Moment(formatted_date).format('MMMM Do YYYY');

		var formatted_time = raw_path.replace("/fixture/", "").split('_').slice(3, 5).join('_');
		formatted_time = formatted_time.replaceAll("_", ":");



		return (
			<Fragment>
				<Helmet>
					<title>Tickets for {teams} marketplace history</title>
					<meta name="description" content={"Score the best deals for the " + teams + " match with our comprehensive ticket pricing guide. Come and join the roaring crowd!"} />
				</Helmet>
				<div className="App container">
					<h1>{teams} - Ticketing marketplace information</h1>
					<h4>Match Details</h4>
					<div>
						<span className="icon">⚽</span>
						<span>{teams}</span>
					</div>
					<div>
						<span className="icon">📆</span>
						<span>{formatted_date + " at " + formatted_time}</span><span> ({relative_time})</span>
					</div>
					<br />
					<h4>Consolidated chart</h4>
					<div className='chart_main'><Line options={chart_options} data={chart_consolidated_data} /></div>
					<p className="noteAlt">
						<span className="chart-ticket-availability">Ticket availability</span>, shows how many tickets were on offer at different points from the
						moment a fixture is announced until the day before the game. This is a crucial parameter reflecting ticket demand and supply balance,
						giving insights into the popularity of the fixture at different moments.<br /><br />
						<span className="chart-price-from">Ticket prices</span> over time. This shift over time helps to track price dynamism, influenced by
						factors such as ticket demand, anticipated competition level, and external market pressures.<br /><br />
						<span className="chart-buckets">Price points</span>, these are divided based on distinct price ranges for tickets, can vary depending upon several factors such as event dynamics, economic conditions, and audience profile. As the graph tracks this, it offers a peek into the evolving pricing strategy for the event.
					</p>
					<br />
					<h4>Individual data point charts</h4>
					<div>
						<div className='chart'><Line options={chart_options} data={chart_tickets_available_data} />
							<p className="noteAlt">
								<span className="chart-ticket-availability">Ticket availability</span>, shown on its own highlights the price volititly more.
							</p>
						</div>
						<div className='chart'><Line options={chart_options} data={chart_price_from_data} />
							<p className="noteAlt">
								<span className="chart-price-from">Ticket prices</span>, tend to move more when the fixture date is approaching.
							</p>
						</div>
						<div className='chart'><Line options={chart_options} data={chart_buckets_data} />
							<p className="noteAlt">
								<span className="chart-buckets">Price points</span>, in most cases will likely show a decrease as tickets are sold.
							</p>
						</div>
					</div>
					<br />
					<h4>Data by Individual data point</h4>
					<div className="data">

						<table className="styled-table">
							<thead>
								<tr>
									<th>Market Check</th>
									<th>Tickets Available</th>
									<th>Lowesft Price</th>
									<th>Price Points</th>
								</tr>
							</thead>
							<tbody>
								{fixture.map((el, index) => {
									let nextEl = fixture[index + 1];
									let next_tickets_available = 0;
									let next_price = 0;
									let next_buckets = 0;

									let icon_tickets_available = "<i class='fa fa-arrow-up' aria-hidden='true'></i><i class='fa fa-arrow-down' aria-hidden='true'></i>";
									let icon_price = "<i class='fa fa-arrow-up' aria-hidden='true'></i><i class='fa fa-arrow-down' aria-hidden='true'></i>";
									let icon_buckets = "<i class='fa fa-arrow-up' aria-hidden='true'></i><i class='fa fa-arrow-down' aria-hidden='true'></i>";

									let current_tickets_available = 0;
									let current_price = 0;
									let current_buckets = 0;

									if (nextEl) {
										next_tickets_available = nextEl.tickets_available.replace("TICKETS AVAILABLE", "");
										next_price = nextEl.price_from.replace("FROM £ ", "");
										next_buckets = nextEl.buckets;

										current_tickets_available = el.tickets_available.replace("TICKETS AVAILABLE", "");
										current_price = el.price_from.replace("FROM £ ", "");
										current_buckets = el.buckets;

										if (next_tickets_available < current_tickets_available) {
											icon_tickets_available = "<i class='fa fa-arrow-up fa-good' aria-hidden='true'></i><i class='fa fa-arrow-down' aria-hidden='true'></i>";
										}
										else if (next_tickets_available > current_tickets_available) {
											icon_tickets_available = "<i class='fa fa-arrow-up' aria-hidden='true'></i><i class='fa fa-arrow-down fa-bad' aria-hidden='true'></i>";
										}

										if (next_price < current_price) {
											icon_price = "<i class='fa fa-arrow-up fa-bad' aria-hidden='true'></i><i class='fa fa-arrow-down' aria-hidden='true'></i>";
										}
										else if (next_price > current_price) {
											icon_price = "<i class='fa fa-arrow-up' aria-hidden='true'></i><i class='fa fa-arrow-down fa-good' aria-hidden='true'></i>";
										}

										if (next_buckets < current_buckets) {
											icon_buckets = "<i class='fa fa-arrow-up fa-good' aria-hidden='true'></i><i class='fa fa-arrow-down' aria-hidden='true'></i>";
										}
										else if (next_buckets > current_buckets) {
											icon_buckets = "<i class='fa fa-arrow-up' aria-hidden='true'></i><i class='fa fa-arrow-down fa-bad' aria-hidden='true'></i>";
										}

									}
									var check_date_relative = Moment(el.date, 'YYYYMMDD').fromNow();;
									var check_date = Moment(el.date).format('DD-MM-YYYY');
									var tickets_available = el.tickets_available.replace("TICKETS AVAILABLE", "");
									var price_from = el.price_from.replace("FROM £ ", "£");

									return (
										<tr className="fixture" key={el._id}>
											<td className="check_date">{check_date} ({check_date_relative})</td>
											<td className="tickets_available">{tickets_available}<span dangerouslySetInnerHTML={{ __html: icon_tickets_available }} /></td>
											<td className="price_from">{price_from}<span dangerouslySetInnerHTML={{ __html: icon_price }} /></td>
											<td className="buckets">{el.buckets}<span dangerouslySetInnerHTML={{ __html: icon_buckets }} /></td>

										</tr>
									)
								}
								)}
							</tbody>
						</table>
						<p className="noteAlt">This table serves as a valuable resource that details all the marketplace shifts associated with a single football fixture, indicating the number of available tickets, their prices, and the segmentation of the pricing structure. By gauging these changing metrics, one can ascertain the overall "must not miss the game" factor.</p>
					</div>
				</div>
			</Fragment>
		);
	}
}

export default App;
