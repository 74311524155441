import React from "react";

const Navbar = () => {
    return (
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark p-3">
            <div className="container">
                <a className="navbar-brand" href="/">
                    <img className="logo" src="../images/site/logo_transparent.svg" height="42" alt="Matchprice.co.uk logo" />
                </a>
                <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarNavDropdown"
                    aria-controls="navbarNavDropdown"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarNavDropdown">
                    <ul className="navbar-nav">
                        <li className="nav-item dropdown">
                            <a
                                className="nav-link dropdown-toggle"
                                href="/"
                                id="navbarDropdownMenuLink"
                                data-bs-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                            >
                                Clubs
                            </a>
                            <div
                                className="dropdown-menu"
                                aria-labelledby="navbarDropdownMenuLink"
                            >
                                <a className="dropdown-item" href="/club/arsenal">Arsenal</a>
                                <a className="dropdown-item" href="/club/aston_villa">Aston Villa</a>
                                <a className="dropdown-item" href="/club/bournemouth">Bournemouth</a>
                                <a className="dropdown-item" href="/club/brentford">Brentford</a>
                                <a className="dropdown-item" href="/club/brighton">Brighton and Hove Albion</a>
                                <a className="dropdown-item" href="/club/burnley">Burnley</a>
                                <a className="dropdown-item" href="/club/chelsea">Chelsea</a>
                                <a className="dropdown-item" href="/club/crystal_palace">Crystal Palace</a>
                                <a className="dropdown-item" href="/club/everton">Everton</a>
                                <a className="dropdown-item" href="/club/fulham">Fulham</a>
                                <a className="dropdown-item" href="/club/liverpool">Liverpool</a>
                                <a className="dropdown-item" href="/club/luton_town">Luton Town</a>
                                <a className="dropdown-item" href="/club/manchester_city">Manchester City</a>
                                <a className="dropdown-item" href="/club/manchester_united">Manchester United</a>
                                <a className="dropdown-item" href="/club/newcastle_united">Newcastle United</a>
                                <a className="dropdown-item" href="/club/nottingham_forest">Nottingham Forest</a>
                                <a className="dropdown-item" href="/club/sheffield_united">Sheffield United</a>
                                <a className="dropdown-item" href="/club/tottenham_hotspur">Tottenham Hotspur</a>
                                <a className="dropdown-item" href="/club/west_ham_united">West Ham United</a>
                                <a className="dropdown-item" href="/club/wolverhampton_wanderers">Wolverhampton Wanderers</a>

                            </div>
                        </li>
                        <li className="nav-item active">
                            <a className="nav-link" href="/competition/premier_league">
                                Premier League <span className="sr-only">(current)</span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/top/most_expensive">
                                Most Expensive
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/news">
                                News
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
};
export default Navbar