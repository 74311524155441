import { FaLocationArrow, FaRegEnvelopeOpen, FaPhone, FaFacebook, FaInstagram, FaLinkedin, FaYoutube, FaTiktok, FaTwitter, FaTelegramPlane } from "react-icons/fa";

const Footer = () => {
    const year = new Date().getFullYear();

    return (
        <footer className="footer-section">
            <div className="container">
                <div className="footer-cta pt-5 pb-5">
                    <div className="row">
                        <div className="col-xl-4 col-md-4 mb-30">
                            <div className="single-cta">
                                <FaLocationArrow className="react-icons" />
                                <div className="cta-text">
                                    <h4>Find us</h4>
                                    <span>30 Old Burlington Street, London, W1S 3AR</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-4 mb-30">
                            <div className="single-cta">
                                <FaPhone className="react-icons" />
                                <div className="cta-text">
                                    <h4>Call us</h4>
                                    <span>+44 (0) 203 489 3273</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-4 mb-30">
                            <div className="single-cta">
                                <FaRegEnvelopeOpen className="react-icons" />
                                <div className="cta-text">
                                    <h4>Mail us</h4>
                                    <span>hello@matchprice.co.uk</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-content pt-5 pb-5">
                    <div className="row">
                        <div className="col-xl-4 col-lg-4 mb-50">
                            <div className="footer-widget">
                                <div className="footer-logo">
                                    <a href="index.html"><img className="logo" src="../images/site/logo_transparent.svg" height="32" alt="Matchprice.co.uk logo" /></a>
                                </div>
                                <div className="footer-text">
                                    <p>A game-changer in the football world - our site digs up the most affordable match ticket prices, getting you right into the heart of the action!</p>
                                </div>
                                <div className="">
                                    <span>Follow us</span>
                                    <a href="https://facebook.com"><FaFacebook className="footer-social-icon" /></a>
                                    <a href="https://twitter.com"><FaTwitter className="footer-social-icon" /></a>
                                    <a href="https://instagram.com"><FaInstagram className="footer-social-icon" /></a>
                                    <a href="https://linkedin.com"><FaLinkedin className="footer-social-icon" /></a>
                                    <a href="https://youtube.com"><FaYoutube className="footer-social-icon" /></a>
                                    <a href="https://tiktok.com"><FaTiktok className="footer-social-icon" /></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 mb-30">
                            <div className="footer-widget">
                                <div className="footer-widget-heading">
                                    <h3>Useful Links</h3>
                                </div>
                                <ul>
                                    <li><a href="/">Home</a></li>
                                    <li><a href="/information/contact">Contact</a></li>
                                    <li><a href="/information/about">About us</a></li>
                                    <li><a href="/information/services">Our Services</a></li>
                                    <li><a href="/information/team">Expert Team</a></li>
                                    <li><a href="/news">Latest News</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 mb-50">
                            <div className="footer-widget">
                                <div className="footer-widget-heading">
                                    <h3>Subscribe</h3>
                                </div>
                                <div className="footer-text mb-25">
                                    <p>Don’t miss out! Subscribe to our news feed and you'll get information when pricess drop below face value. Kindly enter your email address below.</p>
                                </div>
                                <div className="subscribe-form">
                                    <form action="#">
                                        <input type="text" placeholder="Email Address" />
                                        <button><FaTelegramPlane className="footer-subscribe-icon" /></button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="copyright-area">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 text-lg-left">
                            <div className="copyright-text">
                                <p>Copyright &copy; {year}, All Right Reserved matchprice.co.uk</p>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 d-none d-lg-block text-lg-right">
                            <div className="footer-menu" style={{ textAlign: 'right' }}>
                                <ul>
                                    <li><a href="/">Home</a></li>
                                    <li><a href="/information/terms">Terms</a></li>
                                    <li><a href="/information/privacy">Privacy</a></li>
                                    <li><a href="/information/cookie_policy">Cookie</a></li>
                                    <li><a href="/information/disclaimer">Disclaimer</a></li>
                                    <li><a href="/information/affiliate">Affiliate Disclosure</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer >

    );
};

export default Footer;