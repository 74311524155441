import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import ReactDOM from 'react-dom/client';
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap/dist/css/bootstrap.min.css"
import './index.css';
import Navbar from './components/navbar';
import Footer from './components/footer';
import App from './App';

export default function SEO({ title, description, name, type }) {
	return (
		<HelmetProvider>
			{ /* Standard metadata tags */}
			<title>{title}</title>
			<meta name='description' content={description} />
			{ /* End standard metadata tags */}
			{ /* Facebook tags */}
			<meta property="og:type" content={type} />
			<meta property="og:title" content={title} />
			<meta property="og:description" content={description} />
			{ /* End Facebook tags */}
			{ /* Twitter tags */}
			<meta name="twitter:creator" content={name} />
			<meta name="twitter:card" content={type} />
			<meta name="twitter:title" content={title} />
			<meta name="twitter:description" content={description} />
			{ /* End Twitter tags */}
		</HelmetProvider>
	)
}
const helmetContext = {};
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<HelmetProvider context={helmetContext}>
			<Navbar />
			<App />
			<Footer />
		</HelmetProvider>
	</React.StrictMode>
);